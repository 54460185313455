import React from "react"
import { Helmet } from "react-helmet"
import iconGithub from "./img/github.svg"
import iconLinkedin from "./img/linkedin.svg"
import "./index.css"

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Patrick Moriarty - Full Stack Developer</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="index.css" />
      </Helmet>
      <section class="bio section">
        <div class="downarrow-diamond" aria-hidden="true">
          <div class="downarrow-diamond__elem downarrow-diamond__elem--white"></div>
        </div>
        <div class="section__text-container">
          <div class="section__text-container-inner ">
            <h1 class="section__title">Patrick Moriarty</h1>
            <div class="section__icons">
              <a target="_blank" href="https://github.com/patrickjm">
                <img alt="Github" src={iconGithub} width="16" height="16" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/patjmoriarty"
              >
                <img alt="Linkedin" src={iconLinkedin} width="16" height="16" />
              </a>
            </div>
            <p class="section__text">
              Full stack developer based out of Dallas, TX
            </p>
            <p class="section__text">
              React/Typescript/GraphQL/Node/Mongo expertise. CI/CD, Kubernetes
              deployment.
            </p>
            <h2 class="section__subtitle">Work History</h2>
            <ul class="bio__cv">
              <li class="bio__cv-item">
                <h3 class="bio__cv-item-title">
                  <a
                    class="section__link section__link--white"
                    target="_blank"
                    href="https://www.ontada.com/"
                  >
                    McKesson Health - Ontada
                  </a>
                </h3>
                <div class="bio__cv-item-date">2021 — Present</div>
                <div class="bio__cv-item-text">React Developer</div>
              </li>
              <li class="bio__cv-item">
                <h3 class="bio__cv-item-title">
                  <a
                    class="section__link section__link--white"
                    target="_blank"
                    href="https://www.vacobuilt.com/"
                  >
                    Vaco - VacoBuilt
                  </a>
                </h3>
                <div class="bio__cv-item-date">2020 — 2021</div>
                <div class="bio__cv-item-text">Full Stack Developer</div>
              </li>
              <li class="bio__cv-item">
                <h3 class="bio__cv-item-title">
                  <a
                    class="section__link section__link--white"
                    target="_blank"
                    href="https://cognizant.com"
                  >
                    Cognizant Digital Engineering
                  </a>
                </h3>
                <div class="bio__cv-item-date">2018 — 2020</div>
                <div class="bio__cv-item-text">Full Stack Developer</div>
              </li>
              <li class="bio__cv-item">
                <h3 class="bio__cv-item-title">
                  <a
                    class="section__link section__link--white"
                    target="_blank"
                    href="https://lwolf.com"
                  >
                    Lone Wolf Technologies
                  </a>
                </h3>
                <div class="bio__cv-item-date">2017 - 2018</div>
                <div class="bio__cv-item-text">Full Stack Developer</div>
              </li>
              <li class="bio__cv-item">
                <h3 class="bio__cv-item-title">
                  <a
                    class="section__link section__link--white"
                    target="_blank"
                    href="https://utdallas.edu"
                  >
                    University of Texas at Dallas
                  </a>
                </h3>
                <div class="bio__cv-item-date">2013 - 2017</div>
                <div class="bio__cv-item-text">B.S. — Software Engineering</div>
              </li>
            </ul>
            <h2 class="section__subtitle">Articles</h2>
            <ul class="bio__articles">
              <li class="bio__article">
                <a
                  class="section__link section__link--white"
                  target="_blank"
                  href="https://www.digitalocean.com/community/tutorials/react-redux-beacon"
                >
                  Google Analytics on your React/Redux App with Redux Beacon
                </a>
                <div class="bio__cv-item-date">July 2018</div>
              </li>
              <li class="bio__article">
                <a
                  class="section__link section__link--white"
                  target="_blank"
                  href="https://www.digitalocean.com/community/tutorials/react-material-ui"
                >
                  Elegant UX in React with Material-UI
                </a>
                <div class="bio__cv-item-date">June 2018</div>
              </li>
              <li class="bio__article">
                <a
                  class="section__link section__link--white"
                  target="_blank"
                  href="https://www.digitalocean.com/community/tutorials/react-react-select"
                >
                  Searchable, Async Dropdowns in React Using React-Select
                </a>
                <div class="bio__cv-item-date">May 2018</div>
              </li>
              <li class="bio__article">
                <a
                  class="section__link section__link--white"
                  target="_blank"
                  href="https://www.digitalocean.com/community/tutorials/react-react-devtools-intro"
                >
                  React Devtools: A Brief Introduction
                </a>
                <div class="bio__cv-item-date">May 2018</div>
              </li>
              <li class="bio__article">
                <a
                  class="section__link section__link--white"
                  target="_blank"
                  href="https://www.digitalocean.com/community/tutorials/react-higher-order-components"
                >
                  A Quick Intro to React's Higher-Order Components
                </a>
                <div class="bio__cv-item-date">May 2018</div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}
